import axios from "axios";

const fetch = ({ endpoint, token, variables = null, ...props }) => {
  const options = {
    method: variables ? "POST" : "GET" || props.method,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: endpoint,
    params: variables,
    ...props,
  };

  return new Promise((resolve, reject) => {
    axios(options)
      .then(result => {
        resolve(result ? result.data : {});
      })
      .catch(error => reject(error));
  });
};

export default fetch;
