import * as React from "react";
import axios from "axios";

const Query = ({ endpoint, token, variables = null, children }) => {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const isLoading = !data && !error;

  const options = {
    method: variables ? "POST" : "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: endpoint,
    params: variables,
  };

  React.useEffect(
    () => {
      axios(options)
        .then(response => {
          if (!response) setError("No data returned");
          setData(response.data);
        })
        .catch(error => setError(error));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return children({ data, error, isLoading });
};

export default Query;
