import React from "react";
import { useTranslation } from "react-i18next";

import useFetchApi from "../../hooks/useFetchApi";

interface Country {
  idCountry: number;
  countryName: string;
  countryCode: string;
  phoneCode: string;
}

export const CountriesContext = React.createContext<Country[] | null>(null);

const CountriesProvider: React.FC = props => {
  const { t } = useTranslation();

  const { isLoading, isError, data: countries } = useFetchApi<Country[]>(
    "Countries",
    { method: "GET" }
  );

  if (isLoading || isError || !countries) {
    return null;
  }

  // TODO: add test for translated countries
  const translatedCountries = countries.map(country => ({
    ...country,
    countryName: t(`countries.${country.countryName}`),
  }));

  return <CountriesContext.Provider value={translatedCountries} {...props} />;
};

export const useCountries = () => {
  const context = React.useContext(CountriesContext);

  if (!context) {
    throw new Error("useCountries must be used within a CountriesProvider");
  }

  return context;
};

export default CountriesProvider;
