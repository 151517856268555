import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import * as serviceWorker from "./serviceWorker";
import App from "./components/App";
import "./i18n";
import "normalize.css";

ReactGA.initialize("UA-113724862-6");

Sentry.init({
  dsn: `https://48ee7889170541cdbe443c8a22dccfd7@gltp-sup.mindbaz.info/6`,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_API_ENV,
  debug: process.env.REACT_APP_API_ENV !== `production`,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1,
  ignoreErrors: [
    `ResizeObserver loop limit exceeded`,
    `Request failed with status code 403`,
  ],
  // ignore error from localhost.
  beforeSend: event => {
    if (window.location.hostname === `localhost`) {
      return null;
    }
    return event;
  },
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
