import axios from "axios";

const mutate = ({ endpoint, token, variables = null }) => {
  const options = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: endpoint,
    params: variables,
  };

  return new Promise((resolve, reject) => {
    axios(options)
      .then(response => {
        resolve(response);
      })
      .catch(error => reject(error));
  });
};

export default mutate;
