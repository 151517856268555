import { useState, useReducer } from "react";
import { AxiosRequestConfig } from "axios";
// @ts-ignore
import useDeepCompareEffect from "use-deep-compare-effect";

import callApi from "../services/http/callApi";

const FETCH_INIT = "FETCH_INIT";
const FETCH_SUCCESS = "FETCH_SUCCESS";
const FETCH_FAILURE = "FETCH_FAILURE";

interface State<T> {
  isLoading: boolean;
  isError: boolean;
  data: T | null;
  refetch: () => void;
}

type Action<T> =
  | { type: "FETCH_INIT" }
  | { type: "FETCH_SUCCESS"; payload: T }
  | { type: "FETCH_FAILURE" };

const createDataApiReducer = <T>() => (
  state: State<T>,
  action: Action<T>
): State<T> => {
  switch (action.type) {
    case FETCH_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      return state;
  }
};

const useFetchApi = <T>(
  endpoint: string,
  rest: AxiosRequestConfig
): State<T> => {
  const dataApiReducer = createDataApiReducer<T>();
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [state, dispatch] = useReducer(dataApiReducer, {
    isLoading: true,
    isError: false,
    data: null,
    refetch: () => setShouldRefetch(shouldRefetch => !shouldRefetch),
  });

  useDeepCompareEffect(() => {
    // Prevent setting data if the component happens to be unmounted
    let didCancel = false;

    const fetchData = async () => {
      try {
        const response = await callApi(endpoint, rest);
        if (!didCancel) {
          dispatch({ type: FETCH_SUCCESS, payload: response.data.result });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: FETCH_FAILURE });
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [endpoint, rest, shouldRefetch]);

  return state;
};

export default useFetchApi;
