import * as Cookies from "js-cookie";
import { ACCESS_TOKEN, REFRESH_TOKEN, DAID } from "./variables";

const setToken = (refreshToken, accessToken, daid) => {
  // 7 days
  const refreshTokenExpireTime = new Date(
    new Date().getTime() + 60 * 60 * 24 * 1000 * 7
  );

  Cookies.set(REFRESH_TOKEN, refreshToken, {
    expires: refreshTokenExpireTime,
  });

  // 20 min
  const accessTokenExpireTime = new Date(new Date().getTime() + 20 * 60 * 1000);

  Cookies.set(ACCESS_TOKEN, accessToken, {
    expires: accessTokenExpireTime,
  });

  // double authentication
  if (daid) {
    Cookies.set(DAID, daid, {
      expires: 365,
    });
  }
};

export default setToken;
