import React from "react";
import ReactSelect from "react-select";
import { Styles } from "react-select/src/styles";
import { ValueType } from "react-select/src/types";

type Option = {
  value: string;
  label: string;
};

type Props = {
  value?: Option;
  options: Option[];
  onSelect: (value: string) => void;
  width?: string;
  "data-testid"?: string;
};

function Select({ onSelect, width = "80px", ...rest }: Props) {
  const onLocalChange = (selected: ValueType<Option>) => {
    const value = (selected as Option).value;
    onSelect(value);
  };

  const customSelectStyle: Styles = {
    container: provided => ({
      ...provided,
      fontSize: "var(--text-sm)",
    }),
    control: provided => ({
      ...provided,
      width,
    }),
  };

  return (
    <ReactSelect
      styles={customSelectStyle}
      onChange={onLocalChange}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: { ...theme.colors, primary: "var(--pink)" },
      })}
      {...rest}
    />
  );
}

export default Select;
