import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: var(--text-lg);
    font-weight: bold;
    color: var(--grey-700);
  }

  .dots {
    width: 60px;
    margin-top: var(--m-4);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .dots div {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--pink);
    animation: fade 0.8s ease-in-out alternate infinite;
  }

  .dots div:nth-of-type(1) {
    animation-delay: -0.4s;
  }

  .dots div:nth-of-type(2) {
    animation-delay: -0.2s;
  }

  @keyframes fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const Loading = () => {
  const { t } = useTranslation();
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    // Start the loading animation after 200ms otherwise the screen will be flickering
    // Before that time, the user won't feel the interface is loading
    const deferLoadingId = setTimeout(() => {
      setShowLoading(true);
    }, 200);

    return () => clearTimeout(deferLoadingId);
  }, []);

  if (!showLoading) {
    return null;
  }

  return (
    <Container>
      <p>{t("loading")}</p>
      <div className="dots">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Container>
  );
};

export default Loading;
