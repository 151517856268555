import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #30514a;
  width: 300px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  height: 65px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);

  img {
    width: 100px;
  }
`;

export const Links = styled.nav`
  display: flex;
  flex-direction: column;

  a {
    font-size: var(--text-sm);
    padding: var(--m-4) var(--m-5);
    color: white;
    text-decoration: none;

    &:hover {
      background-color: rgba(255, 255, 255, 0.12);
    }

    span {
      margin-left: var(--m-4);
    }
  }
`;

export const Informations = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.75);
  padding: var(--m-5);
  font-size: var(--text-xs);
  background-color: rgba(0, 0, 0, 0.12);

  p {
    text-align: center;
    margin: 0;
  }

  a {
    color: rgba(255, 255, 255, 0.75);
    margin-top: var(--m-1);
  }

  span {
    margin-top: var(--m-1);
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: var(--m-6);

  img {
    margin-top: var(--m-6);
    max-width: 150px;
  }
`;
