import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  SetStateAction,
} from "react";
import useFetchApi from "hooks/useFetchApi";

type Company = {
  idCompany: number;
  companyName: string;
  idCompanyMbz: number;
};

type PersonCompany = {
  firstName: string;
  lastName: string;
  currentCompany: Company;
  userCompanyList: Company[];
};

export const CompanyContext = createContext<{
  company: number | null;
  setCompany: React.Dispatch<SetStateAction<number | null>>;
  data: PersonCompany | null;
} | null>(null);

const PersonCompanyProvider: React.FC = props => {
  const [company, setCompany] = useState<number | null>(null);

  const { data } = useFetchApi<PersonCompany>("PersonCompagnies", {
    method: "GET",
  });

  useEffect(() => {
    if (data) {
      let idCompany;
      if (data.currentCompany) {
        idCompany = data.currentCompany.idCompany;
      } else {
        idCompany = data.userCompanyList[0].idCompany;
      }
      if (idCompany) {
        setCompany(idCompany);
      }
    }
  }, [data]);

  return (
    <CompanyContext.Provider value={{ company, setCompany, data }} {...props} />
  );
};

export const useCompany = () => {
  const context = useContext(CompanyContext);

  if (!context) {
    throw new Error("useCompany must be used within a CompanyProvider");
  }

  return context;
};

export default PersonCompanyProvider;
