import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import localesFr from "./locales/fr.json";
import localesEn from "./locales/en.json";
import localesEs from "./locales/es.json";

export const LOCAL_LANG = "mindbaz_sms_lang";

const resources = {
  fr: { translation: localesFr },
  en: { translation: localesEn },
  es: { translation: localesEs}
};

const lng = window.localStorage.getItem(LOCAL_LANG) || "fr";

i18n.use(initReactI18next).init({
  whitelist: ["fr", "en", "es"],
  fallbackLng: ["fr", "en", "es"],
  resources,
  lng,
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
